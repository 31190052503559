import * as React from "react";
import Layout from "../layouts/Layout";
import DefaultButton from "../components/buttons/DefaultButton";
import { StaticImage } from "gatsby-plugin-image";

// markup
const ThankYouLavoraConNoi = () => {
  return (
    <Layout>
      <div className="container my-5">
        <StaticImage
          src="../assets/images/icon.svg"
          className="mx-auto"
          alt="Prev slide"
          placeholder="blurred"
          layout="fixed"
          width={256}
          height={256}
          objectFit="contain"
        />
        <h2 className="display-5 text-center mt-5">
          Grazie per aver inviato la tua candidatura. Qualora il tuo profilo
          venisse selezionato, verrai ricontattato entro 15 giorni da
          Prestitifaidate.it
        </h2>

        <DefaultButton
          title="Torna su Homepage"
          className="mt-5 btn-outline-dark d-block mx-auto"
          url="/"
        />
      </div>
    </Layout>
  );
};

export default ThankYouLavoraConNoi;
